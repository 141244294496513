.navBar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0.5% 15px;
    background-color: rgba(193, 39, 54, 1);
    font-family: 'Josefin Sans', sans-serif;
    color: rgba(255, 255, 255, 1);
    position: sticky;
    top: 0;
    z-index: 999;
    transition: 0.4s;
}

#ExocetTitle {
    transition: font-size 0.3s ease; 
}


#image {
    transition: width 0.3s ease, height 0.3s ease; 
}

.home .navBar{
    opacity: 0.8;
}

.title-group {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.titleBlock {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.titleBlock > img {
    height: 65px;
    width: auto;
}

.titleBlock > div {
    font-weight: bold;
    font-size: 65px;
}

.title > div {
    font-weight: 550;
    font-size: 15px;
    letter-spacing: 0.1cap;
}

.page-link {
    text-decoration: none;
    color: rgba(255, 255, 255, 1);
    font-size: 22px;
    font-weight: bold;
    margin-right: 20px;
}

.links {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    align-items: center;
    max-width: 70%;
}