.contact-form {
	font-family: 'Josefin Sans', sans-serif;
	display: flex;
    flex-direction: column;
	align-items: center;
}

input[type=text] {
	appearance: none;
    font-size: medium;
}

input[type=text]::placeholder, .message-placeholder {
    color: rgba(55, 75, 87, 1);
	font-style: italic;
}

.message-placeholder{
	margin-bottom: 0;
	padding-bottom: 12.8px;
}

.form-container {
	display: flex;
    flex-direction: row;
	justify-content: center;
	margin: 2% 0;
}

.form-column {
	display: flex;
    flex-direction: column;
    align-items: center;
	align-items: flex-start;
	margin: 0 10%;
}

.input-box, .input-message {
	border: none;
	outline: none;
	background: rgba(rgba(193, 39, 54, 1), .2);
}

.input-box {
	border-bottom: .3em solid rgba(193, 39, 54, 1);
	border-radius: 0;
	position: relative;
	margin: 5% 0;
	width: 250px;
	padding-bottom: .8em;
}

.input-message {
	border: .4em solid rgba(193, 39, 54, 1);
	border-radius: 28px;
	width: 300px;
	height: 140px;
	box-sizing: content-box;
	resize: none;
	overflow-y: auto;
	position: relative;
	padding: 0.7em 0.7em 0.7em 1em;
}

.input-message::-webkit-scrollbar-track {
	box-shadow: inset transparent; 
	border-radius: 0px;
	margin-block: 15px;
 }

.message-container {
	display: flex;
    flex-direction: column;
    align-items: center;
	align-items: flex-start;
}

#send-button {
	margin-top: 30px;
	width: 120px;
	height: 40px;
	cursor: pointer;
}

.notification {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 1rem;
	padding: 1rem;
	background-color: green;
	color: white;
	font-weight: bold;
	z-index: 10; /* Ensure notification is on top */
	opacity: 1;
	transition: opacity 0.5s ease-in-out;
  }
  
  .notification.show {
	opacity: 1;
  }
  
  .notification:not(.show) {
	opacity: 0;
	transform: translateX(-50%) translateY(-100%); /* Slide up on hide */
  }
  
