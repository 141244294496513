.gallery-page {
    color: rgba(193, 39, 54, 1);
    overflow: auto;
    font-family: 'Josefin Sans', sans-serif;
}

.gallery-header {
    font-weight: bold;
    font-size: 45px;
    margin-left: 35px;
    margin-top: 30px;
    margin-bottom: 5px;
    z-index: 100;
}

.gallery-subtitle {
    font-weight: bold;
    font-size: 35px;
    margin-left: 35px;
    z-index: 100;
}