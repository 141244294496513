.countdown {
    font-family: 'Josefin Sans', sans-serif;
    color: rgba(193, 39, 54, 1);
    background-color: rgba(255, 255, 255, 1);
    padding: 20px;
    flex-wrap: nowrap;
    display: inline-block;
    box-sizing: border-box;
    border-radius: 20px;
    font-weight: bolder;
}

.countdown-title {
    font-size: 20px;
    margin: 0px;
    font-weight: bolder;
}

.values-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.countdown-values {
    margin: 0px 10px;
}

.values-text {
    font-size: 20px;
    margin: 10px 0px;
}

.countdown-div, .countdown-values {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.1cap;
}

.values-div > span {
    margin: 0px;
}
